import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal } from 'react-bootstrap';
import ReactMarkdown from "react-markdown";
import { useStaticQuery, graphql } from "gatsby";
import { numberWithCommas } from "@Components/common/common-functions.js";
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";
import ContactEnquiry from "@Components/forms/contact-enquiry";
import PropertyDetailsBanner from "../PropertyDetails/PropertyDetailsBanner/PropertyDetailsBanner";
import "./InternationalProperties.scss";

const InternationalProperties = (props) => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        internationalProperties { 
            id
            Title
            URL
            imagetransforms
            Publish
            Meta_Title
            Meta_Description
            Display_Title
            Display_Address
            Price
            Property_Link
            Upload_Images {
              alternativeText
              url
            }
        }
      }
    }
  `);

  const fetchMoreProperties = async () => {
    setLoading(true);
    try {
      const limit = 12; // Adjusted limit
      const start = page * limit;
      const newProperties = data.glstrapi.internationalProperties.slice(start, start + limit);

      if (newProperties.length === 0) {
        setHasMore(false);
        setLoading(false);
        return;
      }

      setProperties([...properties, ...newProperties]);
      setPage(page + 1);

      // Check if fetched properties exceed the total available
      if (properties.length + newProperties.length >= data.glstrapi.internationalProperties.length) {
        setHasMore(false);
      }

    } catch (error) {
      console.error("Error fetching properties:", error);
    }
    setLoading(false);
  };

  // Initial fetch of properties
  useEffect(() => {
    const initialLimit = 12; // Adjusted initial limit
    setProperties(data.glstrapi.internationalProperties.slice(0, initialLimit));
    if (data.glstrapi.internationalProperties.length <= initialLimit) {
      setHasMore(false);
    }
  }, [data]);

  return (
    <div className="international-prop-wrapper page">
      <Container>
        <div className="header-section">
          <h1>{props.Title}</h1>
          <div className="ip-intro-content rich-text-editer-block">
            <div className="ip-text">
              <ReactMarkdown source={props.Content} allowDangerousHtml />
            </div>
            <div className="ip-button">
                <button className="btn btn-primary" onClick={() => setShowModal(true)}>Contact Us</button>
            </div>      
          </div>
        </div>
        <div className="property-list-wrap">
          <Row>
            {properties.map((property) => {
              
              let processedImages = JSON.stringify({});
              if (property?.imagetransforms?.Image_Transforms) {
                  processedImages = property?.imagetransforms.Image_Transforms;
              }

              // console.log("property",property ); 
              return (
              <Col key={property.id} md={2} lg={4}>
                <div className="property-item">
                  <div className="prop-img-wrap img-zoom">                        
                    <ImageTransform
                      imagesources={property.Upload_Images[0].url}
                      renderer="srcSet"
                      imagename='international-properties.images.propertyTile'
                      attr={{ alt: property?.Title, className: '' }}
                      imagetransformresult={processedImages}
                      id={property?.id}
                    />
                   
                  </div>
                  <div className="prop-content-wrap">                        
                    <div className="prop-price">&pound;{numberWithCommas(property?.Price)}</div>
                    <div className="prop-name">{property?.Title}</div>
                    <div className="prop-address">{property?.Display_Address}</div>
                    <div className="prop-link">
                      <a href={property?.Property_Link} target="_blank">View Details</a>
                    </div>
                  </div>
                </div>
              </Col>
              )
            })}
          </Row>
          {hasMore && (
            <Row>
              <div className="load-more-wrap">
                <button className="btn btn-primary" onClick={fetchMoreProperties} disabled={loading}>
                  {loading ? "Loading..." : "Load More"}
                </button>
              </div>
            </Row>
          )}
        </div>
      </Container>      
      <Modal show={showModal} centered onHide={() => setShowModal(false)} dialogClassName="modal-90w modal-form contact-us-modal-form" aria-labelledby="contact-us-modal">
            <Modal.Header closeButton className="contact-modal-header">
              <Modal.Title><h6>Contact Details</h6></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <ContactEnquiry />
              </div>
            </Modal.Body>
      </Modal>
    </div>
  );
};

export default InternationalProperties;